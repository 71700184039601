<template>
  <div class="edit-table">
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <vxe-button @click="addTable">新增</vxe-button>
        <vxe-button @click="deleteTable">清空</vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="xTable"
      resizable
      show-overflow
      :data="subjectList"
      :edit-config="{
        trigger: 'click',
        mode: 'cell',
      }"
      height="100px"
    >
      <!-- <vxe-table show-overflow="" ref="fineTable" :data="subjectList" height="100px"> -->
      <vxe-table-column
        min-width="100"
        field="feeBudgetCode"
        title="费用预算编码"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="feeBudgetTypeName"
        title="费用预算类型"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="budgetSubjectsName"
        title="预算科目名称"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="ext1"
        title="财年"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="orgName"
        title="组织"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="orgCode"
        title="组织编码"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="customerName"
        title="经销商"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="customerCode"
        title="经销商编码"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="ext10"
        title="职位"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="ext9"
        title="职位编码"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="productLevelName"
        title="产品系列"
      ></vxe-table-column>
      <!-- <vxe-table-column  min-width="200" field="productLevelCode" title="产品系列">
        <template v-slot="{ row ,rowIndex}" >
          <el-select
            v-model="row.productLevelCode"
            filterable
            @change="changeRow('productLevelCode', row,rowIndex)"
            remote
            :disabled="disabled"
            :remote-method="
                (query) => remoteMethod('productLevelCode',query,rowIndex)
              "
          >
            <el-option
              v-for="item in productLevelList"
              :key="item.productLevelCode"
              :label="item.productLevelName"
              :value="item.productLevelCode"
            >
            </el-option>
          </el-select>
        </template>
      </vxe-table-column> -->
      <vxe-table-column
        min-width="100"
        field="ext8"
        title="公司主体"
      ></vxe-table-column>
      <!-- <vxe-table-column min-width="100" field="canUseAmount" title="可用余额"></vxe-table-column> -->
      <vxe-column title="操作" width="80" v-if="!disabled">
        <template #default="{ row }">
          <template>
            <span @click="deletRow(row)" style="color: red; cursor: pointer"
              >删除</span
            >
          </template>
        </template>
      </vxe-column>
    </vxe-table>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import request from '../../../../../../utils/request';

import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'BudgetSubject',
  components: {
    SelectConfig,
  },
  props: {
    saveRef: Function,
    value: Object,
    disabled: Boolean,
    startTime: String,
    endTime: String,
    isControl: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value() {
      this.subjectList = this.value.data;
    },
    startTime() {},
    endTime() {},
  },
  data() {
    return {
      subjectList: [], // 当前关联费用科目数据
      productLevelList: [],
    };
  },
  created() {
    this.getProductLevelList();
  },
  mounted() {
    if (this.saveRef) {
      this.saveRef(this);
    }
  },
  methods: {
    // 删除行
    deletRow(row) {
      console.log(this.subjectList);
      this.$confirm('您确定要删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$refs.xTable.remove(row);
        this.subjectList.forEach((item, index) => {
          if (item.id === row.id) {
            this.subjectList.splice(index, 1);
          }
        });
        this.$emit('change', this.subjectList);
        this.$emit('deleteAssociate', row); // 删除活动明细中的关联信息
        this.$message({ type: 'success', message: '删除成功!' });
      });
    },

    // 获取预算列表
    async getBudgetList(n) {
      const res = await request.post(
        '/tpm/tpmFeeBudgetControlController/list',
        {
          actType: 'project',
          // pageNum: 1,
          // pageSize: 500,
          actBeginDate: n.startTime,
          actEndDate: n.endTime,
        },
      );
      return res.result.data || [];
    },
    // 重置
    async reset(startTime, endTime) {
      // this.value.data = [];
      // this.subjectList = [];
      const data = await this.getBudgetList({
        startTime,
        endTime,
      });

      if (data && data.length) {
        const ids = data.map((item) => item.id);
        const list = this.subjectList.concat([]);
        if (list && list.length) {
          list.forEach((item, index) => {
            if (!ids.includes(item.id)) {
              list.splice(index, 1);
            }
          });
        }
        this.subjectList = list;
        this.value.data = list;
        this.$emit('change', list);
      }
    },

    // 清空列表
    deleteTable() {
      this.$refs.xTable.remove();
      this.value.data = [];
      this.subjectList = [];
      this.$emit('clear');
    },

    // 新增预算信息
    addTable() {
      const { startTime, endTime } = this;
      if (!this.isControl === false && (startTime === '' || endTime === '')) {
        this.$message({
          type: 'error',
          message: '请先选择活动时间!',
        });
      } else {
        const params = {
          functionCode: 'tpm_budget_list',
          data: this.subjectList,
          idKey: 'actCode',
          paramData: {
            actBeginDate: startTime,
            actEndDate: endTime,
          },
        };

        this.$refs.selectConfig.openSelectModal(params);
      }
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    onGetSelect(val) {
      const data = this.getReset(val, [], 'id');
      this.value.data = data;
      this.subjectList = data;
      this.$emit('change', data);
    },
    // 远程搜索
    remoteMethod(field, query, index) {
      if (field === 'productLevelCode') {
        this.getProductLevelList(query, index);
      }
    },
    // 编辑监听
    changeRow(field, row, index) {
      let _item = null;
      switch (field) {
        case 'productLevelCode':
          _item = this.productLevelList.find(
            (v) => v.productLevelCode === row.productLevelCode,
          );
          this.subjectList[index].productLevelName = _item
            ? _item.productLevelName
            : '';
          break;
        default:
          break;
      }
      this.$emit('change', this.subjectList);
    },
    getProductLevelList(query, index) {
      console.log(666);
      const param = {
        productLevelName: query,
      };
      request
        .get('/mdm/mdmProductLevelController/getAllProductLevelList', param)
        .then((res) => {
          if (res.success) {
            this.productLevelList = res.result || [];
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;
}
</style>
